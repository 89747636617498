import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobilePhoneMaskPipe } from '@core-lib/pipes/mobile-phone-mask/mobile-phone-mask.pipe';

@NgModule({
  declarations: [MobilePhoneMaskPipe],
  imports: [CommonModule],
  exports: [MobilePhoneMaskPipe],
})
export class MobilePhoneMaskModule {}
