import { tap, filter, from, switchMap, pairwise } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
const notificationIsSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

class State {
  // permission: NotificationPermission = Notification?.permission;
  permission: 'default' | 'denied' | 'granted' = notificationIsSupported()
    ? Notification?.permission
    : 'default';
  isServiceWorkerEnabled: boolean = false;
  serverPublicKey?: string;
  pushSubscription?: PushSubscription;
}

@Injectable()
export class WebPushStore extends ComponentStore<State> {
  constructor(
    private readonly swPush: SwPush,
    private readonly http: HttpClient
  ) {
    super(new State());

    this.init();
    this.getServerPublicToken();
    this.getPushSubscriptionObj();
    this.sendPushSubscriptionObj();
  }

  selectors$ = {
    isGranted: this.select((s) => s.permission === 'granted'),
    isDefault: this.select((s) => s.permission === 'default'),
    isDenied: this.select((s) => s.permission === 'denied'),
    isEnabled: this.select((s) => s.isServiceWorkerEnabled),
    serverPublicKey: this.select((s) => s.serverPublicKey),
    pushSubscriptionObj: this.select((s) => s.pushSubscription),
  };

  async init() {
    const isEnabled = await this.swPush.isEnabled;
    this.patchState({ isServiceWorkerEnabled: isEnabled });
  }

  enableNotifications = this.effect((origin) =>
    origin.pipe(
      filter(() => notificationIsSupported()),
      filter(() => this.get().permission !== 'granted'),
      switchMap(() => from(Notification?.requestPermission())),
      tap((permission) => this.patchState({ permission }))
    )
  );

  private getServerPublicToken = this.effect((origin) =>
    origin.pipe(
      switchMap(() => this.selectors$.isGranted.pipe(pairwise())),
      filter(([old, neW]) => !old && neW),
      switchMap(() =>
        this.http.get<{ serverPublicKey: string }>(
          '/nest-mobility/web-push/key'
        )
      ),
      tap((resp) => this.patchState({ serverPublicKey: resp.serverPublicKey }))
    )
  );

  private getPushSubscriptionObj = this.effect((origin) =>
    origin.pipe(
      switchMap(() => this.selectors$.serverPublicKey),
      filter((key) => !!key),
      switchMap((serverPublicKey) =>
        from(
          this.swPush.requestSubscription({
            serverPublicKey: serverPublicKey as string,
          })
        )
      ),
      tap((obj) => this.patchState({ pushSubscription: obj }))
    )
  );

  private sendPushSubscriptionObj = this.effect((origin) =>
    origin.pipe(
      switchMap(() => this.selectors$.pushSubscriptionObj),
      filter((obj) => !!obj),
      switchMap((obj) =>
        this.http.post('/nest-mobility/web-push/register', obj)
      )
    )
  );
}
