import { Injectable } from '@angular/core';
import { ProfileApiService } from '@core-lib/modules/profile-store/profile-api.service';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';
import {
  ChangeProfileEmailAction,
  ClearProfileAction,
  LoadingProfileAction,
} from './profile.actions';
import { ProfileStateModule } from './shared/profile-state.interface';
import { Profile } from './shared/profile.interface';

const INITIAL_PROFILE_STATE: ProfileStateModule = {
  loading: false,
  loaded: false,
  profile: null,
};

@State<ProfileStateModule>({
  name: 'profile',
  defaults: INITIAL_PROFILE_STATE,
})
@Injectable()
export class ProfileState {
  constructor(private profileApiService: ProfileApiService) {}

  @Action(LoadingProfileAction)
  loadingProfileAction({ patchState }: StateContext<ProfileStateModule>) {
    patchState({ loaded: false, loading: true });
    return this.profileApiService.getProfile().pipe(
      tap((profile) => patchState({ profile, loaded: true, loading: false })),
      catchError(() => of(patchState({ loaded: false, loading: false })))
    );
  }

  @Action(ClearProfileAction)
  clearProfileAction({ patchState }: StateContext<ProfileStateModule>) {
    patchState({ loaded: false, loading: false, profile: null });
  }

  @Action(ChangeProfileEmailAction)
  changeEmail(
    { patchState, getState }: StateContext<ProfileStateModule>,
    { email }: ChangeProfileEmailAction
  ) {
    patchState({ loaded: false, loading: true });
    const state = getState();
    return this.profileApiService.changeEmail(email).pipe(
      tap((res) => {
        if (res === 'ok') {
          patchState({
            loaded: true,
            loading: false,
            profile: { ...state.profile, email } as Profile,
          });
        }
      }),
      catchError((err) => {
        patchState({ loaded: false, loading: false });
        return err;
      })
    );
  }
}
