import { MapConfig } from '@core-lib/utils/request-mapper/request-mapper';

export interface Session {
  id: string;
  typeOrder: string;
  license: string;
  dateStart: string;
  dateEnd: string;
  imageUrl: string;
  modelName: string;
  sessionId: string;
  vehicleId: number;
  sessionStatus: string;
  colorName: string;
}
export const SESSION_MAP_CONfIG: MapConfig = [
  { frontSide: 'typeOrder', backSide: ['type'] },
  { frontSide: 'license', backSide: ['license'] },
  { frontSide: 'dateStart', backSide: ['dateStart'] },
  { frontSide: 'dateEnd', backSide: ['dateEnd'] },
  { frontSide: 'imageUrl', backSide: ['colorImage'] },
  { frontSide: 'modelName', backSide: ['model'] },
  //   ['sessionId', 'type_order'],
  // ['vehicleId', ['vehicle_id']],
  { frontSide: 'sessionStatus', backSide: ['status'] },
  { frontSide: 'id', backSide: ['_id'] },
];

export interface SessionDTO {
  colorImage: string;
  dateEnd: string;
  dateStart: string;
  model: string;
  modelImage: string;
  status: string;
  type: string;
  _id: string;
}
