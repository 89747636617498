import { Component, Self } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { UpdaterComponent } from '@core-lib/components/updater/updater.component';
import { Lang } from '@core-lib/consts/lang';
import { DestroyService } from '@core-lib/services/destroy.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil, tap } from 'rxjs';
import { SettingsStoreService } from '@core-lib/modules/settings-store/settings-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DestroyService],
})
export class AppComponent {
  constructor(
    public translate: TranslateService,
    private settingStore: SettingsStoreService,
    private swUpdate: SwUpdate,
    private _snackBar: MatSnackBar,
    @Self() private readonly destroy$: DestroyService
  ) {
    translate.addLangs([Lang.En, Lang.Ru]);
    translate.setDefaultLang(Lang.En);

    this.settingStore.setLang();
    this.settingStore.lang$
      .pipe(
        tap((language) => this.translate.use(language)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    ///нужно сделать красивее, сделано для проверки
    this.swUpdate.versionUpdates
      .pipe(
        filter((e) => e.type !== 'VERSION_INSTALLATION_FAILED'),
        takeUntil(this.destroy$)
      )
      .subscribe((x) => {
        console.log('versionUpdates');
        console.log(x);
        this.openUpdateBar();
      });

    setInterval(() => {
      console.log('checkForUpdate');
      this.swUpdate.checkForUpdate().then();
    }, 30000);
  }

  openUpdateBar(): void {
    this._snackBar.openFromComponent(UpdaterComponent, {
      panelClass: 'updateMessage',
      verticalPosition: 'bottom',
      horizontalPosition: 'start',
    });
  }
}
