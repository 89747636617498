import { Bridge } from './model';
import { Observable } from 'rxjs';

export function bridgeMap<FRONT>(config: Bridge<FRONT, any>) {
  return (source: Observable<[] | {}>) => {
    const bmap = (value: {} | []) => {
      const res: any = {};

      Object.entries(config.config).forEach(
        ([key, fn]) => (res[key] = (fn as any)(value))
      );

      return res;
    };

    return new Observable<typeof config['type']>((subscriber) => {
      source.subscribe({
        next(value) {
          const res: any = Array.isArray(value) ? value.map(bmap) : bmap(value);

          subscriber.next(res);
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });
    });
  };
}
