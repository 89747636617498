<h3>{{ 'select_region_title' | translate }}</h3>
<ng-container
  *ngIf="{
    currentCity: currentCity$ | async
  } as data">
  <mat-selection-list
    (selectionChange)="selectedCity($event)"
    [multiple]="false">
    <mat-list-option
      *ngFor="let city of cities"
      [value]="city"
      [selected]="data.currentCity === city">
      {{ city | translate }}
    </mat-list-option>
  </mat-selection-list>
</ng-container>
