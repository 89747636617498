export interface Regions {
  [key: string]: {
    latitude: number;
    longitude: number;
  };
}

export const REGIONS: Regions = {
  moscow: {
    latitude: 55.755814,
    longitude: 37.617635,
  },
  st_petersburg: {
    latitude: 59.938951,
    longitude: 30.315635,
  },
  kazan: {
    latitude: 55.796289,
    longitude: 49.108795,
  },
  rostov_on_don: {
    latitude: 47.222078,
    longitude: 39.720349,
  },
  nizhny_novgorod: {
    latitude: 56.326797,
    longitude: 44.006516,
  },
  krasnodar: {
    latitude: 45.03547,
    longitude: 38.975313,
  },
  sochi: {
    latitude: 43.585525,
    longitude: 39.723062,
  },
  simferopol: {
    latitude: 44.948237,
    longitude: 34.100318,
  },
  samara: {
    latitude: 53.195538,
    longitude: 50.101783,
  },
  novo_sibirsk: {
    latitude: 55.030199,
    longitude: 82.92043,
  },
  kaliningrad: {
    latitude: 54.710454,
    longitude: 20.512733,
  },
  ekaterinburg: {
    latitude: 56.838011,
    longitude: 60.597465,
  },
  ufa: {
    latitude: 54.735147,
    longitude: 55.958727,
  },
  voronezh: {
    latitude: 51.661535,
    longitude: 39.200287,
  },
  chelyabinsk: {
    latitude: 55.15402,
    longitude: 61.42915,
  },
  krasnoyarsk: {
    latitude: 56.01839,
    longitude: 92.86717,
  },
  perm: {
    latitude: 58.01046,
    longitude: 56.25017,
  },
  barnaul: {
    latitude: 58.01046,
    longitude: 56.25017,
  },
};
