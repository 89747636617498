import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-bottom-login-sheet',
  templateUrl: './bottom-login-sheet.component.html',
  styleUrls: ['./bottom-login-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomLoginSheetComponent {
  constructor(private _bottomSheet: MatBottomSheet) {}

  close() {
    this._bottomSheet.dismiss();
  }
}
