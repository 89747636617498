import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { SettingsStateModule } from './shared/settings-state.interface';
import {
  SettingsCityChanged,
  SettingsCommonChanged,
  SettingsLanguageSet,
} from './settings.actions';
import { TranslateService } from '@ngx-translate/core';
import { Theme } from '@core-lib/modules/themes/theme.const';
import { Lang } from '@core-lib/consts/lang';

const INITIAL_SETTINGS_STATE: SettingsStateModule = {
  loading: false,
  loaded: false,
  city: '',
  language: undefined,
  theme: Theme.Light,
  timezone: '+03:00',
};

@State<SettingsStateModule>({
  name: 'settings',
  defaults: INITIAL_SETTINGS_STATE,
})
@Injectable()
export class SettingsState {
  constructor(private store: Store, private translate: TranslateService) {}

  @Selector()
  public static getCity(state: SettingsStateModule): string {
    return state.city;
  }

  @Selector()
  public static getLanguage(state: SettingsStateModule): Lang {
    return state.language!;
  }

  @Selector()
  public static getTheme(state: SettingsStateModule): Theme {
    return state.theme || Theme.Light;
  }

  @Selector()
  public static getCommonSettings(
    state: SettingsStateModule
  ): Pick<SettingsStateModule, 'language' | 'city' | 'theme'> {
    const { language, city, theme = Theme.Light } = state;
    return { language, city, theme };
  }

  @Action(SettingsCityChanged)
  settingsCityChanged(
    { patchState }: StateContext<SettingsStateModule>,
    { payload }: SettingsCityChanged
  ) {
    patchState({
      city: payload.city,
    });
  }

  @Action(SettingsCommonChanged)
  settingsCommonChanged(
    { patchState }: StateContext<SettingsStateModule>,
    { payload }: SettingsCommonChanged
  ) {
    patchState({ ...payload });
  }

  @Action(SettingsLanguageSet)
  settingsLanguageSet({
    getState,
    patchState,
  }: StateContext<SettingsStateModule>) {
    const browserLang = this.translate.getBrowserLang();
    const browserLangDetected = browserLang?.match(/ru/) ? Lang.Ru : Lang.En;
    const language = getState().language || browserLangDetected;
    patchState({ language });
  }
}
