import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SessionsService } from './sessions-store.service';
import { NgxsModule } from '@ngxs/store';
import { SessionState } from './sessions-store.state';
import { DestroyService } from '@core-lib/services/destroy.service';
@NgModule({
  imports: [BrowserModule, NgxsModule.forFeature([SessionState])],
  providers: [SessionsService, DestroyService],
})
export class SessionsStoreModule {}
