import { Injectable } from '@angular/core';
import { MenuItem } from '@core-lib/components/sidenav/shared/login-params.interface';
import { ProfileStoreService } from '@core-lib/modules/profile-store/profile-store.service';
import { ScoringScreensEnum } from '@core-lib/modules/profile-store/shared/profile.interface';
import { Observable, map } from 'rxjs';
import { menuesConfig } from './mobility-menu.consts';
import { UserMenuesCodes } from './mobility-menu.interface';

@Injectable()
export class MobilityMenuService {
  constructor(private profileStoreService: ProfileStoreService) {}

  getMenues$<T extends object = { page: string }>(
    code: UserMenuesCodes
  ): Observable<MenuItem<T>[]> {
    return this.profileStoreService.profile$.pipe(
      map((profile) => {
        let profileMenues: MenuItem[] = [];
        const scoringScreenStatus = profile
          ? profile?.scoring.scoringScreenStatus
          : ScoringScreensEnum.UNAUTHORIZED;
        const menuesForPageCode = menuesConfig.find(
          (menuesForPage) => menuesForPage.code === code
        );
        const rulesForStatus =
          menuesForPageCode?.rules[scoringScreenStatus] || [];
        profileMenues =
          menuesForPageCode?.pages.filter((page) =>
            [...rulesForStatus]?.find((pageCode) =>
              page.queryParams?.page
                ? pageCode === page.queryParams?.page
                : pageCode === page.routes[0]
            )
          ) || [];
        return profileMenues as MenuItem<T>[];
      })
    );
  }
}
