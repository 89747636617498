import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgSessionsComponent } from './svg-sessions.component';

@NgModule({
  declarations: [SvgSessionsComponent],
  exports: [SvgSessionsComponent],
  imports: [CommonModule],
})
export class SvgSessionsModule {}
