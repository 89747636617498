export class LoadingActiveSessionsAction {
  public static readonly type = '[Sessions Store] Loading Active Sessions';
}

export class LoadingSessionAction {
  public static readonly type = '[Sessions Store] Loading Current Session';
  constructor(public payload: { vehicleId: number }) {}
}

export class ClearSessionsAction {
  public static readonly type = '[Sessions Store] Clear Sessions Action';
}
