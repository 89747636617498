import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, interval, of, switchMap } from 'rxjs';
import { map, skip, startWith, take, tap } from 'rxjs/operators';

@Injectable()
export class TimerService {
  private oneSecond = 1000;
  private _startTimerSubject = new BehaviorSubject<number>(-1);

  timer$ = this._startTimerSubject.asObservable().pipe(
    switchMap((timerLength) =>
      of(timerLength).pipe(
        switchMap((timerLength) =>
          interval(this.oneSecond).pipe(
            take(timerLength),
            map((val) => timerLength - val - 1)
          )
        ),
        startWith(timerLength)
      )
    )
  );

  startTimer(timerLength: number) {
    this._startTimerSubject.next(timerLength);
  }
}
