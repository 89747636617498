import { Component, EventEmitter, Input, Output, Self } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '@core-lib/components/sidenav/shared/login-params.interface';
import { AuthService } from '@core-lib/modules/auth-service/auth.service';
import { BottomSheetAuthService } from '@core-lib/modules/bottom-sheet-auth/bottom-sheet-auth.service';
import { ProfileStoreService } from '@core-lib/modules/profile-store/profile-store.service';
import { TokenControlService } from '@core-lib/modules/token-control/token-control.service';
import { DestroyService } from '@core-lib/services/destroy.service';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, takeUntil, switchMap } from 'rxjs';
import { MobilityMenuService } from '../mobility-menu/mobility-menu.service';
@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  providers: [DestroyService],
})
export class DrawerComponent {
  @Output() drawerClick = new EventEmitter();
  @Input() showMenu!: boolean | null;

  menuItems2$ = this.mobilityMenuService
    .getMenues$('main2')
    .pipe(switchMap((menues) => this.getMenuTranslate(menues)));
  menuItems$ = this.mobilityMenuService
    .getMenues$('main')
    .pipe(switchMap((menues) => this.getMenuTranslate(menues)));

  phone = '+7(909) 555-32-43';
  hasToken$ = this.tokenService.currentAccessToken$.pipe(
    map((token) => !!token)
  );

  profile$ = this.profileService.profile$;
  constructor(
    private tokenService: TokenControlService,
    private bottomSheetAuthService: BottomSheetAuthService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private profileService: ProfileStoreService,
    private mobilityMenuService: MobilityMenuService,
    @Self() private readonly destroy$: DestroyService
  ) {}

  onToolbarClick() {
    this.drawerClick.emit();
  }

  logout(): void {
    this.authService
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate(['/']));
  }

  login(): void {
    this.bottomSheetAuthService.checkAuth();
  }

  getMenuTranslate(menuItems: MenuItem[] = []): Observable<MenuItem[]> {
    return this.translate
      .stream(menuItems.map((item) => item.name))
      .pipe(
        map((translate: Record<string, string>) =>
          menuItems.map((item) => ({ ...item, name: translate[item.name] }))
        )
      );
  }
}
