import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BottomSheetCitySelectorService } from './bottom-sheet-city-selector.service';
import { BottomSheeetCitySelectorComponent } from './components/bottom-sheet-city-selector/bottom-sheet-city-selector.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [BottomSheeetCitySelectorComponent],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatListModule,
    MatBottomSheetModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [BottomSheeetCitySelectorComponent],
  providers: [BottomSheetCitySelectorService],
})
export class BottomSheetCitySelectorModule {}
