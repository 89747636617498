import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mapFunction } from '@core-lib/utils/request-mapper/request-mapper';
import { map, Observable } from 'rxjs';
import { Session, SESSION_MAP_CONfIG } from './shared/session.interface';

@Injectable({ providedIn: 'root' })
export class SessionsApiService {
  constructor(private httpClient: HttpClient) {}

  getActiveDrivings(): Observable<Session[]> {
    return this.httpClient
      .get<Session[]>('/nest-mobility/sessions/client', {})
      .pipe(
        map((res) =>
          res.map((session) =>
            mapFunction<Session>(session, SESSION_MAP_CONfIG)
          )
        )
      );
  }
}
