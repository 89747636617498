import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { ThemesModule } from '../themes/themes.module';
import { SettingsStoreService } from './settings-store.service';
import { SettingsState } from './settings.state';

@NgModule({
  imports: [
    BrowserModule,
    NgxsModule.forFeature([SettingsState]),
    ThemesModule,
    TranslateModule,
  ],
  providers: [SettingsStoreService],
})
export class SettingsStoreModule {}
