import { ScoringScreensEnum } from '@core-lib/modules/profile-store/shared/profile.interface';
import { MenuItem } from '@core-lib/components/sidenav/shared/login-params.interface';
import {
  MenuItemsDebtsIds,
  MenuItemsDebtsPages,
  MenuItemsMain2Ids,
  MenuItemsMainIds,
  MenuItemsSettingsIds,
  MenuItemsSettingsPages,
  UserMenuesCodes,
} from './mobility-menu.interface';

export const menuItemsDebtsInitial: MenuItem<MenuItemsDebtsPages>[] = [
  {
    routes: ['/debts'],
    queryParams: { page: 'cards', side: 'right' },
    name: 'binded_cards',
    classes: 'border secondary',
  },
  {
    routes: ['/debts'],
    queryParams: { page: 'schedule', side: 'right' },
    name: 'payment_graph',
    classes: 'border secondary',
  },
  {
    routes: ['/debts'],
    queryParams: { page: 'fines', side: 'right' },
    name: 'fines_and_recalculates',
    classes: 'border secondary',
  },
  {
    routes: ['/debts'],
    queryParams: { page: 'history', side: 'right' },
    name: 'payment_history',
    classes: 'border secondary',
  },
];

export const menuItemsDebtsAccess: {
  [key in ScoringScreensEnum]: MenuItemsDebtsIds[];
} = {
  [ScoringScreensEnum.IN_PROGRESS]: ['cards', 'schedule', 'fines', 'history'],
  [ScoringScreensEnum.WAIT_PHOTO]: ['cards'],
  [ScoringScreensEnum.WAIT_PHOTO_AGAIN]: [
    'cards',
    'schedule',
    'fines',
    'history',
  ],
  [ScoringScreensEnum.REJECTED]: ['cards', 'fines', 'history'],
  [ScoringScreensEnum.APPROVED]: ['cards', 'schedule', 'fines', 'history'],
  [ScoringScreensEnum.UNAUTHORIZED]: [],
};

export const menuItemsSettingsInitial: MenuItem<MenuItemsSettingsPages>[] = [
  {
    routes: ['/settings'],
    queryParams: { page: 'common', side: 'right' },
    name: 'setting_common',
    classes: 'border secondary',
  },
  {
    routes: ['/settings'],
    queryParams: { page: 'profile', side: 'right' },
    name: 'profile',
    classes: 'border secondary',
  },
  {
    routes: ['/settings'],
    queryParams: { page: 'scoring', side: 'right' },
    name: 'verification_of_documents',
    classes: 'border secondary',
  },
];

export const menuItemsSettingsAccess: {
  [key in ScoringScreensEnum]: MenuItemsSettingsIds[];
} = {
  [ScoringScreensEnum.IN_PROGRESS]: ['common', 'profile', 'scoring'],
  [ScoringScreensEnum.WAIT_PHOTO]: ['common', 'profile', 'scoring'],
  [ScoringScreensEnum.WAIT_PHOTO_AGAIN]: ['common', 'profile', 'scoring'],
  [ScoringScreensEnum.REJECTED]: ['common', 'profile', 'scoring'],
  [ScoringScreensEnum.APPROVED]: ['common', 'profile', 'scoring'],
  [ScoringScreensEnum.UNAUTHORIZED]: ['common'],
};

const menuItemsMainInitial: MenuItem[] = [
  {
    routes: ['main'],
    name: 'to_the_main',
    icon: 'home',
  },
  {
    routes: ['sessions'],
    name: 'to_the_drive',
    icon: 'sessions',
  },
  {
    routes: ['debts'],
    name: 'payments',
    icon: 'debts',
  },
  {
    routes: ['settings'],
    name: 'settings',
    icon: 'settings',
  },
  {
    routes: ['faq'],
    name: 'support',
    icon: 'sos',
  },
];

export const menuItemsMainAccess: {
  [key in ScoringScreensEnum]: MenuItemsMainIds[];
} = {
  [ScoringScreensEnum.IN_PROGRESS]: [
    'main',
    'sessions',
    'debts',
    'settings',
    'faq',
  ],
  [ScoringScreensEnum.WAIT_PHOTO]: [
    'main',
    'sessions',
    'debts',
    'settings',
    'faq',
  ],
  [ScoringScreensEnum.WAIT_PHOTO_AGAIN]: [
    'main',
    'sessions',
    'debts',
    'settings',
    'faq',
  ],
  [ScoringScreensEnum.REJECTED]: ['debts', 'settings', 'faq'],
  [ScoringScreensEnum.APPROVED]: [
    'main',
    'sessions',
    'debts',
    'settings',
    'faq',
  ],
  [ScoringScreensEnum.UNAUTHORIZED]: ['main', 'settings', 'faq'],
};

const menuItemsMain2Initial: MenuItem[] = [
  {
    routes: ['website'],
    name: 'website',
    icon: 'home',
    external: 'https://mobility.hyundai.ru/',
  },
  {
    routes: ['documents'],
    name: 'documents',
    icon: 'documents',
  },
];

export const menuItemsMain2Access: {
  [key in ScoringScreensEnum]: MenuItemsMain2Ids[];
} = {
  [ScoringScreensEnum.IN_PROGRESS]: ['website', 'documents'],
  [ScoringScreensEnum.WAIT_PHOTO]: ['website', 'documents'],
  [ScoringScreensEnum.WAIT_PHOTO_AGAIN]: ['website', 'documents'],
  [ScoringScreensEnum.REJECTED]: ['website', 'documents'],
  [ScoringScreensEnum.APPROVED]: ['website', 'documents'],
  [ScoringScreensEnum.UNAUTHORIZED]: ['website'],
};

export const menuesConfig: {
  code: UserMenuesCodes;
  rules: {
    [key in ScoringScreensEnum]:
      | MenuItemsSettingsIds[]
      | MenuItemsDebtsIds[]
      | MenuItemsMainIds[]
      | MenuItemsMain2Ids[];
  };
  pages: MenuItem[];
}[] = [
  {
    code: 'main',
    rules: menuItemsMainAccess,
    pages: menuItemsMainInitial,
  },
  {
    code: 'main2',
    rules: menuItemsMain2Access,
    pages: menuItemsMain2Initial,
  },
  {
    code: 'settings',
    rules: menuItemsSettingsAccess,
    pages: menuItemsSettingsInitial,
  },
  {
    code: 'debts',
    rules: menuItemsDebtsAccess,
    pages: menuItemsDebtsInitial,
  },
];
