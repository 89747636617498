import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WebPushStore } from './web-push.store';

@NgModule({
  imports: [CommonModule],
  providers: [WebPushStore],
})
export default class WebPushModule {
  constructor(private readonly webPushStore: WebPushStore) {}
}
