import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { MenuItem } from './shared/login-params.interface';
import { SvgIconDirective } from '../svg-icon/svg-icon.directive';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements AfterContentInit {
  public svgIconsTmp!: Record<string, TemplateRef<SvgIconDirective>>;

  @Input() menuItems!: MenuItem[] | null;
  @Input() id: string = '';
  @Output() selected = new EventEmitter<MenuItem>();

  @ContentChildren(SvgIconDirective) svgIcons!: QueryList<SvgIconDirective>;

  ngAfterContentInit(): void {
    this.setSvgIconsTmp();
  }

  onSelected(menuItem: MenuItem) {
    this.selected.emit(menuItem);
  }

  setLink(menuItem: MenuItem) {
    if (menuItem?.external) {
      window?.open(menuItem.external, '_blank');
    }
  }

  private setSvgIconsTmp() {
    this.svgIconsTmp = this.svgIcons.reduce((res, { appSvgIcon, template }) => {
      return { ...res, [appSvgIcon]: template };
    }, {});
  }
}
