import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgSettingsComponent } from './svg-settings.component';

@NgModule({
  declarations: [SvgSettingsComponent],
  exports: [SvgSettingsComponent],
  imports: [CommonModule],
})
export class SvgSettingsModule {}
