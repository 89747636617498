import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DestroyService } from '@core-lib/services/destroy.service';

@Component({
  selector: 'app-login-code',
  templateUrl: './login-code.component.html',
  styleUrls: ['./login-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class LoginCodeComponent implements OnInit {
  @Output() codeChange = new EventEmitter<string>();
  @Input() length: number = 0;
  @ViewChild('formRow', { static: false }) rows!: ElementRef;
  formCodeArr: { value: string }[] = [];
  get inputs(): HTMLInputElement[] {
    return this.rows.nativeElement.elements;
  }

  ngOnInit() {
    this.initForm(this.length);
  }

  initForm(length: number) {
    this.formCodeArr = [];
    for (let i = 0; i < length; i++) {
      this.formCodeArr.push({ value: '' });
    }
    this.codeChange.emit('');
  }

  keyUp(event: KeyboardEvent, index: number, inputValue: string) {
    if (!inputValue) {
      if (!this.formCodeArr[index].value) {
        if (event.code === 'Backspace' && index >= 1) {
          this.inputs[index - 1]?.focus();
        }
      }
    } else if (index < this.formCodeArr.length) {
      this.inputs[index + 1]?.focus();
    }
    this.formCodeArr[index].value = inputValue;
    this.codeChange.emit(this.formCodeArr.map(({ value }) => value).join(''));
  }
}
