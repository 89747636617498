import { ChangeDetectionStrategy, Component, Self } from '@angular/core';
import { LoginStore } from './login.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LoginStore],
})
export class LoginComponent {
  constructor(@Self() public readonly loginStore: LoginStore) {}
}
