import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { of, Subscribable, tap, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/operators';
import { TimerService } from '@core-lib/services/timer.service';
import { AuthService } from '@core-lib/modules/auth-service/auth.service';

const URLS = {
  CONSENT_URL: {
    titleLocale: 'enter_phone_next_text_pt2',
    url: 'https://www.hyundai.ru/mobility_documents/consent_personal#pdf',
  },
};

class LoginState {
  isCodeWrong: boolean = false;
  disableFormPhone: boolean = false;
  visibleCodeForm: boolean = false;
  isPhoneWrong: boolean = false;
}

@Injectable()
export class LoginStore extends ComponentStore<LoginState> {
  formGroup: FormGroup = new FormGroup({
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    accept: new FormControl('', [Validators.required, Validators.requiredTrue]),
  });
  codeForm = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.maxLength(4),
  ]);

  public isCodeWrong$: Subscribable<boolean> = this.select(
    (state) => state.isCodeWrong
  );
  public isPhoneWrong$: Subscribable<boolean> = this.select(
    (state) => state.isPhoneWrong
  );
  public disableFormPhone$: Subscribable<boolean> = this.select(
    (state) => state.disableFormPhone
  );
  public visibleCodeForm$: Subscribable<boolean> = this.select(
    (state) => state.visibleCodeForm
  );

  urls = URLS;

  constructor(
    private authService: AuthService,
    public timerService: TimerService
  ) {
    super(new LoginState());
  }

  changePhone() {
    this.formGroup.patchValue({ phone: '' });
    this.patchState({ visibleCodeForm: false });
    this.disableForm(false);
  }

  onCodeChange(code: string) {
    this.codeForm.setValue(code);
  }

  disableForm(value: boolean) {
    value ? this.formGroup.disable() : this.formGroup.enable();
  }

  public readonly continue = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      switchMap(() =>
        this.authService
          .collTheAuthColl({ phone: '+7' + this.formGroup.value.phone })
          .pipe(
            map((resp) => resp.status === 'code_sent'),
            tap((isDisableForm) => this.disableForm(isDisableForm)),
            tap(() =>
              this.patchState({
                isCodeWrong: false,
                visibleCodeForm: true,
              })
            ),
            tap(() => this.timerService.startTimer(60)),
            catchError(() => of().pipe())
          )
      )
    )
  );

  public readonly accept = this.effect((origin$: Observable<void>) =>
    origin$.pipe(
      switchMap(() =>
        this.authService
          .otpLogin({
            code: this.codeForm.value,
            phone: '+7' + this.formGroup.value.phone,
          })
          .pipe(
            catchError((error) =>
              of(error).pipe(
                tap(() =>
                  this.patchState({
                    isCodeWrong: true,
                  })
                )
              )
            )
          )
      )
    )
  );
}
