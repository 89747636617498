interface RequiredGeneric extends Object {}

export class Bridge<
  FRONT extends RequiredGeneric,
  BACK extends RequiredGeneric
> {
  type?: FRONT extends [] ? FRONT[] : FRONT;
  constructor(public readonly config: Config<FRONT, BACK>) {}
}

type Config<FRONT, BACK> = {
  [key in keyof (FRONT extends Array<any> ? FRONT[0] : FRONT)]: (
    data: BACK extends Array<any> ? BACK[0] : BACK
  ) => unknown;
};
