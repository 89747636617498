import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { catchError, finalize, of, tap, throwError } from 'rxjs';
import { TokenControlService } from '../token-control/token-control.service';
import { Router } from '@angular/router';

interface LoginParams {
  phone: string;
  code?: string;
}
interface LoginResponse {
  allowDev: boolean;
  codeTtl: number;
  status: 'code_sent';
}

interface TokenPackage {
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  tokenType: string;
}

@Injectable()
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private tokenControlService: TokenControlService,
    private route: Router,
    private ngZone: NgZone
  ) {}

  collTheAuthColl(params: LoginParams) {
    return this.httpClient.post<LoginResponse>('/auth', params);
  }

  otpLogin(params: LoginParams) {
    return this.httpClient
      .post<TokenPackage>('/auth/otp', params)
      .pipe(tap((value) => this.tokenControlService.setToken(value)));
  }

  refreshToken() {
    const refreshToken =
      this.tokenControlService.currentAccessToken?.refreshToken;

    if (!refreshToken) {
      return throwError(() => new Error('RefreshToken Not Exist'));
    }

    return this.httpClient
      .post<TokenPackage>('/auth/refresh', { refreshToken })
      .pipe(tap((value) => this.tokenControlService.setToken(value)));
  }

  logout() {
    return this.httpClient.post<LoginResponse>('/auth/logout', {}).pipe(
      finalize(() => {
        this.tokenControlService.removeToken();
        this.ngZone.run(() => this.route.navigate(['/start']));
      })
    );
  }
}
