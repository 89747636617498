export class LoadingProfileAction {
  public static readonly type = '[Profile Store] Loading Profile';
}

export class ClearProfileAction {
  public static readonly type = '[Profile Store] Clear Profile';
}

export class ChangeProfileEmailAction {
  public static readonly type = '[Profile Store] Change Profile Email';
  constructor(public email: string) {}
}
