<div class="container">
  <div class="container__inner">
    <button
      class="container__close-btn"
      mat-raised-button
      mat-icon-button
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <app-login></app-login>
  </div>
</div>
