import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DestroyService } from '@core-lib/services/destroy.service';
import { NgxsModule } from '@ngxs/store';
import { ProfileApiService } from './profile-api.service';
import { ProfileStoreService } from './profile-store.service';
import { ProfileState } from './profile.state';
@NgModule({
  imports: [BrowserModule, NgxsModule.forFeature([ProfileState])],
  providers: [ProfileStoreService, DestroyService, ProfileApiService],
})
export class ProfileStoreModule {}
