import { Injectable, Self } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DestroyService } from '@core-lib/services/destroy.service';
import { filter, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { SettingsStoreService } from '../settings-store/settings-store.service';
import { BottomSheeetCitySelectorComponent } from './components/bottom-sheet-city-selector/bottom-sheet-city-selector.component';

@Injectable()
export class BottomSheetCitySelectorService {
  private authBottomSheet!: MatBottomSheetRef;
  checkingCitySubject = new Subject<boolean>();

  city$ = this.settingsStoreService.city$;

  constructor(
    private settingsStoreService: SettingsStoreService,
    private _bottomSheet: MatBottomSheet,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.checkingCitySubject
      .asObservable()
      .pipe(
        filter((checkingCity) => checkingCity),
        withLatestFrom(this.city$),
        filter(([_, token]) => !token),
        tap(() => this.openBottomSheet()),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.city$
      .pipe(
        filter((token) => !!token),
        tap(() => this.close()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  openBottomSheet(): void {
    this.authBottomSheet = this._bottomSheet.open(
      BottomSheeetCitySelectorComponent,
      {
        disableClose: true,
      }
    );
  }

  close(): void {
    if (this.authBottomSheet) {
      this.authBottomSheet.dismiss();
    }
  }
}
