import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActiveDrivingsDtoNamespace } from '@core-lib/modules/documents-store/shared/documents.dto';
import ActiveDrivingsDtoResponse = ActiveDrivingsDtoNamespace.ActiveDrivingsDtoResponse;

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService {
  constructor(private httpClient: HttpClient) {}

  getActiveDrivings(): Observable<ActiveDrivingsDtoResponse> {
    return this.httpClient.post<ActiveDrivingsDtoResponse>(
      '/api/pmobile/driving/getActiveDrivings',
      {}
    );
  }
}
