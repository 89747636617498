import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileStoreService } from './../modules/profile-store/profile-store.service';
import { ScoringScreensEnum } from './../modules/profile-store/shared/profile.interface';

@Injectable({
  providedIn: 'root',
})
export class ScoringHardGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private profileService: ProfileStoreService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkScoring();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkScoring();
  }

  checkScoring() {
    return this.profileService.profile$.pipe(
      map((profile) => {
        if (
          profile?.scoring.scoringScreenStatus === ScoringScreensEnum.REJECTED
        ) {
          this.router.navigate(['settings'], {
            queryParams: { page: 'scoring', side: 'right' },
          });
          return false;
        }
        return !!true;
      })
    );
  }
}
