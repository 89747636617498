import { Bridge } from '@core-lib/utils/bridge-map-pipe-rxjs/model';

export type Profile = ProfileOrdinaryPart & ProfileMasterUserPart;

export interface ProfileOrdinaryPart {}

export interface ProfileMasterUserPart {
  email: string;
  phoneNumber: number;
  firstName: string;
  lastName: string;
  middleName: string;
  isYearGift: boolean;
  scoring: {
    status: string;
    scoringScreenStatus: ScoringScreensEnum;
  };
}

export enum ScoringScreensEnum {
  IN_PROGRESS = 'inProgress',
  WAIT_PHOTO = 'waitPhoto',
  WAIT_PHOTO_AGAIN = 'waitPhotoAgain',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  UNAUTHORIZED = 'unauthorized',
}

export interface ProfileResponse {
  email: string;
  number: number;
  passport: {
    first_name: string;
    last_name: string;
    middle_name: string;
  };
  yearPromotion: {
    type: string;
  };
  yearGift: unknown;
}

export interface ProfileMasterUserResponse {
  id: string;
  scoringStatus: {
    scoringCompleted: boolean;
    scoringCompletedAt: null;
    status: string;
    approved: boolean;
    hasDocuments: false;
  };
  phoneNumber: string;
  email: string;
  patronymic: string;
  fullName: string;
  firstName: string;
  lastName: string;
  avatar: null;
  emailOnVerify: null;
  phoneOnVerify: null;
  isAccountRemoving: boolean;
  yearPromotion: {
    type: string;
  };
  yearGift: unknown;
}

export interface ChangeProfileEmilResponse {
  result: string;
  id: unknown;
}

export const PROFILE_BRIDGE = new Bridge<ProfileOrdinaryPart, ProfileResponse>(
  {}
);

export const PROFILE_MASTER_USER_BRIDGE = new Bridge<
  ProfileMasterUserPart,
  ProfileMasterUserResponse
>({
  email: (e) => e.email,
  phoneNumber: (e) => e.phoneNumber,
  firstName: (e) => e.firstName,
  lastName: (e) => e.lastName,
  middleName: (e) => e.patronymic,
  scoring: (e) => ({
    status: e.scoringStatus.status,
    scoringScreenStatus: getScoringScreen(e.scoringStatus.status),
  }),
  isYearGift: (e) => !!e.yearGift || !!e.yearPromotion,
});

const getScoringScreen = (status: string) => {
  switch (status) {
    case 'DENIED':
      return ScoringScreensEnum.REJECTED;
    case 'NONE':
      return ScoringScreensEnum.WAIT_PHOTO;
    case 'NEED_TO_RESEND':
      return ScoringScreensEnum.WAIT_PHOTO_AGAIN;
    case 'APPROVED':
      return ScoringScreensEnum.APPROVED;
    case 'SECURITY_CHECK_NEEDED':
    case 'IN_PROGRESS':
      return ScoringScreensEnum.IN_PROGRESS;
    default:
      return ScoringScreensEnum.IN_PROGRESS;
  }
};
