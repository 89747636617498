import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemesDirective } from './theme.directive';
import { DestroyService } from '@core-lib/services/destroy.service';

@NgModule({
  declarations: [ThemesDirective],
  imports: [CommonModule],
  providers: [DestroyService],
  exports: [ThemesDirective],
})
export class ThemesModule {}
