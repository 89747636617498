<ul class="navigation">
  <li [class]="'navigation__item ' + (link?.classes || '')"
      routerLinkActive="navigation__item--active"
      [routerLink]="link.external ? null : link.routes"
      [queryParams]="link.queryParams"
      (click)="setLink(link)"
      *ngFor="let link of menuItems">
    <a class="navigation__link"
       matLine
       (click)="onSelected(link)">
      <ng-container *ngIf="(link?.icon || '') as icon">
        <span *ngIf="svgIconsTmp[icon]" class="icon-left">
          <ng-container [ngTemplateOutlet]="svgIconsTmp[icon]"></ng-container>
        </span>
      </ng-container>

      <span>{{ link.name }}</span>

      <span *ngIf="svgIconsTmp['arrowRight']" class="icon-right">
        <ng-container [ngTemplateOutlet]="svgIconsTmp['arrowRight']"></ng-container>
      </span>
    </a>
  </li>
</ul>
