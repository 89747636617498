import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgHomeComponent } from './svg-home.component';

@NgModule({
  declarations: [SvgHomeComponent],
  exports: [SvgHomeComponent],
  imports: [CommonModule],
})
export class SvgHomeModule {}
