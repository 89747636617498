import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgProfileComponent } from './svg-profile.component';

@NgModule({
  declarations: [SvgProfileComponent],
  exports: [SvgProfileComponent],
  imports: [CommonModule],
})
export class SvgProfileModule {}
