import { ScoringHardGuard } from '@core-lib/guards/scoring-rejected.guard';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core-lib/guards/auth.guard';
import { MainContainerComponent } from './pages/main-container/main-container.component';

const routes: Routes = [
  {
    path: 'start',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/start-page/start-page.module').then(
        (m) => m.StartPageModule
      ),
  },
  {
    path: '',
    component: MainContainerComponent,
    children: [
      {
        path: 'main',
        loadChildren: () =>
          import('./pages/main-page/main-page.module').then(
            (m) => m.MainPageModule
          ),
        canActivate: [ScoringHardGuard],
      },
      {
        path: 'technical',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/techical-page/technical-page.module').then(
            (m) => m.TechnicalPageModule
          ),
        canActivate: [ScoringHardGuard],
      },

      {
        path: 'sessions',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/sessions-page/sessions-page.module').then(
            (m) => m.SessionsPageModule
          ),
        canActivate: [AuthGuard, ScoringHardGuard],
      },
      {
        path: 'documents',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/documents-page/documents-page.module').then(
            (m) => m.DocumentsPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'debts',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/debts-page/debts-page.module').then(
            (m) => m.DebtsPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'faq',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'settings',
        pathMatch: 'full',
        loadChildren: () =>
          import('./pages/settings-page/settings-page.module').then(
            (m) => m.SettingsPageModule
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/start',
      },
    ],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found-page/not-found-page.module').then(
        (m) => m.NotFoundPageModule
      ),
  },
];
const ROUTER_EXTRA_CONFIG: ExtraOptions = {
  relativeLinkResolution: 'legacy',
  enableTracing: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, ROUTER_EXTRA_CONFIG)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
