import { Injectable } from '@angular/core';
import { environment } from '@mobility-pwa/environments/environment.dev';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';
import { SessionsApiService } from './sessions-api.service';
import {
  ClearSessionsAction,
  LoadingActiveSessionsAction,
} from './sessions-store.actions';
import { Session } from './shared/session.interface';
import { SessionStateModule } from './shared/sessions-state.interface';

const INITIAL_STATE: SessionStateModule = {
  loading: false,
  loaded: false,
  activeSessions: null,
  curentSession: null,
};

@State<SessionStateModule>({
  name: 'sessions',
  defaults: INITIAL_STATE,
})
@Injectable()
export class SessionState {
  constructor(private sessionsApiService: SessionsApiService) {}

  @Selector()
  public static getActiveSessions(
    state: SessionStateModule
  ): Session[] | undefined | null {
    return state.activeSessions;
  }

  @Action(LoadingActiveSessionsAction)
  loadingActiveSessionsAction({
    patchState,
  }: StateContext<SessionStateModule>) {
    patchState({ loaded: false, loading: true });
    return this.sessionsApiService.getActiveDrivings().pipe(
      tap((sessions) => {
        const updatedSessions = sessions.map((session) => ({
          ...session,
          imageUrl: environment.baseUrl + '/public' + session.imageUrl,
        }));
        patchState({
          activeSessions: updatedSessions,
          loaded: true,
          loading: false,
        });
      }),
      catchError(() => of(patchState({ loaded: false, loading: false })))
    );
  }

  @Action(ClearSessionsAction)
  clearSessionsAction({ patchState }: StateContext<SessionStateModule>) {
    patchState({
      loaded: false,
      loading: true,
      activeSessions: null,
      curentSession: null,
    });
  }
}
