export const DOCUMENTS = {
  // consent to the transfer of personal data
  CONSENT_URL_DOCUMENT: {
    titleLocale: 'consent_transfer_personal_data',
    url: 'https://www.hyundai.ru/mobility_documents/consent_personal#pdf',
  },
  // privacy policy
  PRIVACY_URL: {
    titleLocale: 'privacy_policy',
    url: 'https://mobility.hyundai.ru/privacy/',
  },
  // terms of use
  AGREEMENT_URL: {
    titleLocale: 'terms_of_use',
    url: 'https://www.hyundai.ru/mobility_documents/agreement#pdf',
  },
  // commercial offer for legal entities
  COMMERCIAL_OFFER: {
    titleLocale: 'commercial_offer',
    url: 'https://www.hyundai.ru/mobility_documents/commercial_offer#pdf',
  },
  // typical lease Freedom
  TYPICAL_RENT_CONTRACT_FREEDOM: {
    titleLocale: 'typical_rent_contract_freedom',
    url: 'https://www.hyundai.ru/mobility_documents/arenda_freedom#pdf',
  },
  // typical lease "Country"
  TYPICAL_RENT_CONTRACT_COUNTRY: {
    titleLocale: 'typical_rent_contract_country',
    url: 'https://www.hyundai.ru/mobility_documents/arenda_country#pdf',
  },
  // typical lease "Travel"
  TYPICAL_RENT_CONTRACT_LETO: {
    titleLocale: 'typical_rent_contract_leto',
    url: 'https://www.hyundai.ru/mobility_documents/dop_leto#pdf',
  },
  // // commercial offer for legal entities
  // LEGAL_ENTITY_TARIFF_URL: {
  //   titleLocale: 'legal_entity_tariff',
  //   url: 'https://mobility.hyundai.ru/documents/commercial.pdf',
  // },
  // NEW YEAR PROMO
  // NEW_YEAR_GAME_RULES: {
  //     titleLocale: 'new_year_2021_title',
  //     url: 'https://www.hyundai.ru/mobility_documents/beautybox_pravila#pdf',
  // },
  // https://super-hml.atlassian.net/browse/HM1-889
  // rules of the "Year Together" promotion
  // DISCOUNT_RULES_YEAR_TOGETHER: {
  //     titleLocale: 'discount_year_together',
  //     url: 'https://www.hyundai.ru/mobility_documents/rules_year_together#pdf',
  // },
  // supplementary agreement to the "Year Together"
  // DS_DISCOUNT_RULES_YEAR_TOGETHER: {
  //     titleLocale: 'ds_discount_year_together',
  //     url: 'https://www.hyundai.ru/mobility_documents/dop_year_together#pdf',
  // },
  // additional agreement to "Drive summer with Hyundai mobility"
  // ADDITIONAL_AGREEMENT_KILOMETERS: {
  //   titleLocale: 'additional_agreement_kilometers',
  //   url: 'https://api.mobility.hyundai.ru/media/doc/mobility/HyundaiMobility2022KilometersOfer.pdf',
  // },
  // // rules of "Drive summer with Hyundai mobility"
  // RULES_KILOMETERS: {
  //   titleLocale: 'rules_kilometers',
  //   url: 'https://api.mobility.hyundai.ru/media/doc/mobility/HyundaiMobility2022KilometersRules.pdf',
  // },
  // PRIORITY_RENT: {
  //   titleLocale: 'rules_of_loyalty_program',
  //   url: 'https://www.hyundai.ru/mobility_documents/rules_year_together#pdf',
  // },
};
