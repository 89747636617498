import { DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconDirective } from './svg-icon.directive';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({
  declarations: [SvgIconDirective],
  imports: [CommonModule],
  exports: [SvgIconDirective],
})
export class SvgIconModule {
  icons = [
    'sos',
    'scoring-again',
    'scoring-approved',
    'scoring-reject',
    'scoring-take-a-photo',
    'scoring-waiting',
  ];

  constructor(registry: MatIconRegistry, ds: DomSanitizer) {
    this.icons.forEach((icon) =>
      registry.addSvgIcon(
        icon,
        ds.bypassSecurityTrustResourceUrl('/assets/icons/svg/' + icon + '.svg')
      )
    );
  }
}
