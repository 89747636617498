import { MobilityMenuModule } from './modules/mobility-menu/mobility-menu.module';
import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeen from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeRuExtra from '@angular/common/locales/extra/ru';
import localeru from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdaterModule } from '@core-lib/components/updater/updater.module';
import { Lang } from '@core-lib/consts/lang';
import { AuthControlModule } from '@core-lib/modules/auth-service/auth-service.module';
import { BottomSheetAuthModule } from '@core-lib/modules/bottom-sheet-auth/bottom-sheet-auth.module';
import { BottomSheetCitySelectorModule } from '@core-lib/modules/bottom-sheet-city-selector/bottom-sheet-city-selector.module';
import { DocumentsStoreModule } from '@core-lib/modules/documents-store/documents-store.module';
import { ProfileStoreModule } from '@core-lib/modules/profile-store/profile-store.module';
import { SessionsStoreModule } from '@core-lib/modules/sessions-store/sessions-store.module';
import { SettingsStoreModule } from '@core-lib/modules/settings-store/settings-store.module';
import { ThemesModule } from '@core-lib/modules/themes/themes.module';
import { TokenControlModule } from '@core-lib/modules/token-control/token-control.module';
import WebPushModule from '@core-lib/modules/web-push/web-push.module';
import { environment } from '@mobility-pwa/environments/environment.dev';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AppMobilityHttpInterceptor } from './app-http.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainContainerModule } from './pages/main-container/main-container.module';

registerLocaleData(localeru, localeRuExtra);
registerLocaleData(localeen, localeEnExtra);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
export function getcurrentLang(): string {
  return (
    JSON.parse(localStorage.getItem('settings') || '{}').language || Lang.En
  );
}

const MAT_FORM_FIELD_OPTIONS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000',
    }),
    //store modules
    SettingsStoreModule,
    ProfileStoreModule,
    SessionsStoreModule,
    DocumentsStoreModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['settings', 'profile'],
    }),
    //shared modules
    ThemesModule,
    BottomSheetAuthModule,
    BottomSheetCitySelectorModule,
    TokenControlModule,
    AuthControlModule,
    UpdaterModule,

    //pages modules
    MainContainerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000',
    }),
    MobilityMenuModule,
    WebPushModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppMobilityHttpInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: getcurrentLang() },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MAT_FORM_FIELD_OPTIONS,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
