<app-svg-logo></app-svg-logo>

<div class="phone-block">
  <ng-container *ngIf="profile$ | async as profile">
    <a href="tel:+{{ profile.phoneNumber }}" class="phone head-type">+7 {{ profile.phoneNumber | mobilePhone }}</a>
  </ng-container>
</div>

<app-sidenav
  class="sidenav"
  [menuItems]="menuItems$ | async"
  (selected)="onToolbarClick()">
  <ng-template appSvgIcon="home">
    <app-svg-home></app-svg-home>
  </ng-template>
  <ng-template appSvgIcon="help">
    <app-svg-help></app-svg-help>
  </ng-template>
  <ng-template appSvgIcon="messages">
    <app-svg-messages></app-svg-messages>
  </ng-template>
  <ng-template appSvgIcon="debts">
    <app-svg-debts></app-svg-debts>
  </ng-template>
  <ng-template appSvgIcon="settings">
    <app-svg-settings></app-svg-settings>
  </ng-template>
  <ng-template appSvgIcon="sessions">
    <app-svg-sessions></app-svg-sessions>
  </ng-template>
  <ng-template appSvgIcon="sos">
    <mat-icon svgIcon="sos"></mat-icon>
  </ng-template>
</app-sidenav>

<span class="spacer"></span>

<mat-divider></mat-divider>

<app-sidenav
  class="sidenav"
  [menuItems]="menuItems2$ | async"
  (selected)="onToolbarClick()">
  <ng-template appSvgIcon="home">
    <app-svg-home></app-svg-home>
  </ng-template>
  <ng-template appSvgIcon="documents">
    <app-svg-documents></app-svg-documents>
  </ng-template>
</app-sidenav>
<mat-divider></mat-divider>

<p *ngIf="hasToken$ | async" class="logout" (click)="logout()">
  {{ 'out' | translate }}
</p>
<p *ngIf="(hasToken$ | async) === false" class="logout" (click)="login()">
  {{ 'authorization' | translate }}
</p>
