import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { ToolbarModule } from '@core-lib/components/toolbar/toolbar.module';
import { DestroyService } from '@core-lib/services/destroy.service';
import { DrawerModule } from '@mobility-pwa/app/modules/drawer/drawer.module';
import { MainContainerComponent } from './main-container.component';

@NgModule({
  declarations: [MainContainerComponent],
  exports: [MainContainerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSidenavModule,
    RouterModule,
    LayoutModule,
    MatIconModule,
    DrawerModule,
    ToolbarModule,
  ],
  providers: [DestroyService],
})
export class MainContainerModule {}
