import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgDebtsComponent } from './svg-debts.component';

@NgModule({
  declarations: [SvgDebtsComponent],
  exports: [SvgDebtsComponent],
  imports: [CommonModule],
})
export class SvgDebtsModule {}
