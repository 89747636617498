import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list/public-api';
import { REGIONS } from '@core-lib/consts/regions';
import { SettingsStoreService } from '@core-lib/modules/settings-store/settings-store.service';

@Component({
  selector: 'app-bottom-sheet-city-selector',
  templateUrl: './bottom-sheet-city-selector.component.html',
  styleUrls: ['./bottom-sheet-city-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheeetCitySelectorComponent {
  cities = Object.keys(REGIONS);
  currentCity$ = this.settingsStoreService.city$;

  constructor(private settingsStoreService: SettingsStoreService) {}

  selectedCity(city: MatSelectionListChange) {
    const value = city.source.selectedOptions.selected[0].value;
    this.settingsStoreService.setCity(value);
  }
}
