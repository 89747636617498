import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DocumentsStateModule } from '@core-lib/modules/documents-store/shared/documents-state.interface';
import { DOCUMENTS } from '@core-lib/modules/documents-store/shared/documents-state.consts';
import { GetActiveDrivingsActions } from '@core-lib/modules/documents-store/documents-store.actions';
import { DocumentsApiService } from '@core-lib/modules/documents-store/documents-api.service';
import { tap } from 'rxjs';
import { TokenControlService } from '@core-lib/modules/token-control/token-control.service';
import { environment } from '@mobility-pwa/environments/environment';
import { ActiveDrivingsDtoNamespace } from '@core-lib/modules/documents-store/shared/documents.dto';
import ActiveDrivingsDto = ActiveDrivingsDtoNamespace.ActiveDrivingsDto;

const INITIAL_STATE: DocumentsStateModule = {
  documents: Object.values(DOCUMENTS),
};

@State<DocumentsStateModule>({
  name: 'documents',
  defaults: INITIAL_STATE,
})
@Injectable()
export class DocumentsState {
  constructor(
    private documentsApiService: DocumentsApiService,
    private tokenControlService: TokenControlService
  ) {}
  @Action(GetActiveDrivingsActions)
  getActiveDrivingsActions({ patchState }: StateContext<DocumentsStateModule>) {
    return this.documentsApiService.getActiveDrivings().pipe(
      tap(({ result: activeDrivings }) => {
        const getQueryStr = (item: ActiveDrivingsDto) =>
          new URLSearchParams({
            token:
              this.tokenControlService.currentAccessToken?.accessToken || '',
            vehicle_id: item.vehicle_id.toString(),
          }).toString();
        patchState({
          documents: [
            ...Object.values(DOCUMENTS),
            ...(activeDrivings || []).map((driving) => ({
              modelName: `${driving.type_order} ${driving.model.enum} ${driving.license}`,
              titleLocale: 'subscription_agreement',
              url: `${environment.baseUrl}/api/pmobile/getpdf?${getQueryStr(
                driving
              )}`,
            })),
          ],
        });
      })
    );
  }
}
