import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mobilePhone',
})
export class MobilePhoneMaskPipe implements PipeTransform {
  transform(
    value: string | number | undefined = '',
    mask: string = '(###) ###-##-##'
  ): string {
    if (!value) return '';

    const arr = value?.toString().split('');

    if (arr && arr[0] === '+' && arr[1] === '7') {
      arr.splice(0, 2).join('');
    } else if (arr && (arr[0] === '7' || arr[0] === '8')) {
      arr.splice(0, 1).join('');
    }

    let _val = arr
      .map((el) => el.replace(/^[\D|\(|\)]+/g, ''))
      .join('')
      .substring(0, 10);

    const result =
      _val.length < 3
        ? _val
        : mask
            .split('')
            .map((ch) => {
              if (!_val[0]) return;
              if (ch === '#') {
                ch = _val[0];
                _val = _val.substring(1);
                return ch;
              }
              return ch;
            })
            .join('');

    return result;
  }
}
