import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  SettingsCityChanged,
  SettingsCommonChanged,
  SettingsLanguageSet,
} from './settings.actions';
import { SettingsState } from './settings.state';
import { SettingsStateModule } from '@core-lib/modules/settings-store/shared/settings-state.interface';
import { Theme } from '@core-lib/modules/themes/theme.const';

@Injectable()
export class SettingsStoreService {
  @Select(SettingsState.getCity) city$!: Observable<string>;
  @Select(SettingsState.getLanguage) lang$!: Observable<string>;
  @Select(SettingsState.getTheme) theme$!: Observable<Theme>;

  @Select(SettingsState.getCommonSettings) commonSettings$!: Observable<
    Pick<SettingsStateModule, 'language' | 'city' | 'theme'>
  >;

  constructor(private store: Store) {}

  setCity(city: string) {
    this.store.dispatch(new SettingsCityChanged({ city }));
  }

  setLang() {
    this.store.dispatch(new SettingsLanguageSet());
  }

  setCommonSettings(
    settings: Pick<SettingsStateModule, 'language' | 'city' | 'theme'>
  ) {
    this.store.dispatch(new SettingsCommonChanged(settings));
  }
}
