import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SidenavModule } from '@core-lib/components/sidenav/sidenav.module';
import { SvgIconModule } from '@core-lib/components/svg-icon/svg-icon.module';
import { BottomSheetCitySelectorModule } from '@core-lib/modules/bottom-sheet-city-selector/bottom-sheet-city-selector.module';
import { SettingsStoreModule } from '@core-lib/modules/settings-store/settings-store.module';
import { DestroyService } from '@core-lib/services/destroy.service';
import { TranslateModule } from '@ngx-translate/core';
import { SvgDebtsModule } from '../svg/nav/svg-debts/svg-debts.module';
import { SvgDocumentsModule } from '../svg/nav/svg-documents/svg-documents.module';
import { SvgHomeModule } from '../svg/nav/svg-home/svg-home.module';
import { SvgLogoModule } from '../svg/nav/svg-logo/svg-logo.module';
import { SvgMessagesModule } from '../svg/nav/svg-messages/svg-messages.module';
import { SvgProfileModule } from '../svg/nav/svg-profile/svg-profile.module';
import { SvgSessionsModule } from '../svg/nav/svg-sessions/svg-sessions.module';
import { SvgSettingsModule } from '../svg/nav/svg-settings/svg-settings.module';
import { SvgHelpModule } from '../svg/svg-help/svg-help.module';
import { DrawerComponent } from './drawer.component';
import { MobilePhoneMaskModule } from '@core-lib/pipes/mobile-phone-mask/mobile-phone-mask.module';

@NgModule({
  declarations: [DrawerComponent],
  exports: [DrawerComponent],
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    MatCardModule,
    MatMenuModule,
    MatButtonModule,
    SidenavModule,
    MatIconModule,
    SvgHomeModule,
    SvgIconModule,
    SvgHelpModule,
    SvgMessagesModule,
    SvgDebtsModule,
    SvgProfileModule,
    SvgSettingsModule,
    SvgSessionsModule,
    SvgDocumentsModule,
    SvgLogoModule,
    SettingsStoreModule,
    BottomSheetCitySelectorModule,
    MatDividerModule,
    TranslateModule,
    MobilePhoneMaskModule,
  ],
  providers: [DestroyService],
})
export class DrawerModule {}
