import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, Self, ViewChild } from '@angular/core';
import { ACTUAL_BREAKPOINTS } from '@core-lib/consts/breakpoints.const';
import { BottomSheetCitySelectorService } from '@core-lib/modules/bottom-sheet-city-selector/bottom-sheet-city-selector.service';
import { DestroyService } from '@core-lib/services/destroy.service';
import {
  BehaviorSubject,
  buffer,
  map,
  Observable,
  startWith,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  providers: [DestroyService],
})
export class MainContainerComponent implements OnInit {
  @ViewChild('toolbar', { static: true }) toolbar!: ElementRef;
  drawerOpenedSubject = new BehaviorSubject<boolean>(false);
  scrollSubject = new BehaviorSubject<number>(0);
  showToolbarSubject = new BehaviorSubject<boolean>(false);
  showToolbar$!: Observable<boolean>;

  offset = 20;
  headerHeight = 56;
  scrollTrottleTime = 100;
  isMain = false;

  showMenu$ = this.breakpointObserver.observe(ACTUAL_BREAKPOINTS).pipe(
    tap(({ breakpoints }) => {
      if (
        breakpoints[Breakpoints.Small] ||
        breakpoints[Breakpoints.Medium] ||
        breakpoints[Breakpoints.Large] ||
        breakpoints[Breakpoints.XLarge]
      ) {
        this.drawerOpenedSubject.next(true);
      }
    }),
    map(
      ({ breakpoints }) =>
        breakpoints[Breakpoints.Small] ||
        breakpoints[Breakpoints.Medium] ||
        breakpoints[Breakpoints.Large] ||
        breakpoints[Breakpoints.XLarge]
    )
  );

  constructor(
    private bottomSheetCitySelectorService: BottomSheetCitySelectorService,
    private breakpointObserver: BreakpointObserver,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.bottomSheetCitySelectorService.checkingCitySubject.next(true);
  }

  ngOnInit(): void {
    this.scrollSubject
      .asObservable()
      .pipe(
        buffer(this.scrollSubject.pipe(throttleTime(this.scrollTrottleTime))),
        withLatestFrom(
          this.showToolbarSubject.asObservable().pipe(startWith(true))
        ),
        map(([bufferValue, showToolbarCurrent]) => {
          const prev = bufferValue[0];
          const next = bufferValue[bufferValue.length - 1];
          if (!prev && prev !== 0) {
            return showToolbarCurrent;
          }
          if (next <= this.headerHeight) {
            return true;
          }
          const diff = next - prev;
          if (showToolbarCurrent) {
            if (diff >= this.offset) {
              return false;
            }
            if (diff < this.offset) {
              return true;
            }
          }
          if (!showToolbarCurrent) {
            if (-diff >= this.offset) {
              return true;
            }
            if (-diff < this.offset) {
              return false;
            }
          }
          return showToolbarCurrent;
        }),
        startWith(true),
        tap((showToolbar) => this.showToolbarSubject.next(showToolbar)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  onDrawerClick(showMenu: boolean | null) {
    if (!showMenu) {
      this.drawerOpenedSubject.next(!this.drawerOpenedSubject.value);
    }
  }

  openedChange(opened: boolean) {
    this.drawerOpenedSubject.next(opened);
  }

  onscroll() {
    this.scrollSubject.next(
      (this.toolbar as any).elementRef?.nativeElement?.scrollTop
    );
  }
}
