import { Injectable, Self } from '@angular/core';
import { DestroyService } from '@core-lib/services/destroy.service';
import { Select, Store } from '@ngxs/store';
import { Observable, takeUntil, tap } from 'rxjs';
import { TokenControlService } from '../token-control/token-control.service';
import {
  ClearSessionsAction,
  LoadingActiveSessionsAction,
} from './sessions-store.actions';
import { SessionState } from './sessions-store.state';
import { Session } from './shared/session.interface';

@Injectable()
export class SessionsService {
  @Select(SessionState.getActiveSessions) activeSessions$!: Observable<
    Session[]
  >;

  constructor(
    private tokenControService: TokenControlService,
    private store: Store,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.tokenControService.currentAccessToken$
      .pipe(
        tap((token) =>
          token
            ? this.loadActiveSessions()
            : this.store.dispatch(new ClearSessionsAction())
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  loadActiveSessions() {
    this.store.dispatch(new LoadingActiveSessionsAction());
  }

}
