
<h2 class="header">{{'authorization-greetings' | translate}}</h2>
<p>{{'log_in_to_start_using_the_service' | translate}}</p>
<form [formGroup]="loginStore.formGroup" class="form">
    <mat-form-field>
        <mat-label>{{'phone_number' | translate}}</mat-label>
        <input type="tel"
               matInput
               formControlName="phone"
               mask="(000) 000-00-00"
               prefix="+7 " 
               placeholder="+7 (___) ___-__-__"
               class="form__input"
               >
    </mat-form-field>
    <ng-container *ngIf="(loginStore.visibleCodeForm$ | async) === false ">
        <div class="form-accepting">
            <mat-checkbox formControlName="accept">
            </mat-checkbox>
            <span class="form-accepting__label">
                <span>{{'enter_phone_next_text_pt1' | translate}} </span>
                <a [href]="loginStore.urls.CONSENT_URL.url" target="_blank" class="form-accepting__link">{{'enter_phone_next_text_pt2' | translate}} </a>
                <span>{{'enter_phone_next_text_pt3' | translate}} </span>
                <a  class="form-accepting__link">{{'enter_phone_next_text_pt4' | translate}} </a>
            </span>
        </div>
        <button class="form__button" [disabled]="!loginStore.formGroup.valid" (click)="loginStore.continue()" mat-flat-button color="primary" >{{'continue' | translate}}</button>
    </ng-container>
</form>

<div class="code-form" *ngIf="(loginStore.visibleCodeForm$ | async) ">
    <span class="code-form__block">
        {{'the_code_from_the_sms' | translate}}
        <a class="code-form__retry" (click)="loginStore.changePhone()"> {{'to_change_number' | translate}}</a>
    </span>
    <app-login-code [length]="4" (codeChange)="loginStore.onCodeChange($event)"></app-login-code>
    <span class="code-form__block" *ngIf="{timer: loginStore.timerService.timer$| async} as obj">
    <span class="code-form__error" [class.code-form__error--visible]="loginStore.isCodeWrong$ | async">{{'error_code' | translate}}</span>
    <p>{{'to_send_the_code_again_after' | translate}} {{obj.timer | toTimeString}}</p>
        <a class="code-form__retry" [class.code-form__retry--blocked]="obj.timer!==0"  (click)="loginStore.continue()">{{'send_code_again' | translate}}</a>
        <button class="form__button" [disabled]="!loginStore.codeForm.valid" (click)="loginStore.accept()" mat-flat-button color="primary" >{{'continue' | translate}}</button>
    </span>
</div>

