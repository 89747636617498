export type MapConfig = {
  frontSide: string;
  backSide: string[];
}[];

export const mapFunction = <T>(obj1: any, mapConfig: MapConfig): T => {
  const result: any = {};
  mapConfig.forEach(
    (mapRule) => (result[mapRule.frontSide] = getValuee(obj1, mapRule.backSide))
  );
  return result as T;
};

const getValuee = (object: any, valueNames: string[]) => {
  let value = { ...object };
  valueNames.forEach((name) => (value = value ? setValue(value[name]) : ''));
  return value;
};

const stringBoolean = new Map([
  ['true', true],
  ['false', false],
]);

const setValue = (value: string) => {
  if (stringBoolean.has(value)) {
    return stringBoolean.get(value);
  }
  return value;
};
