import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toTimeString',
})
export class ToTimeStringPipe implements PipeTransform {
  secondInMenute = 60;
  transform(value: number | null): string {
    if (!value) {
      return '00:00';
    }
    const seconds = value % this.secondInMenute;
    const menutes = (value - seconds) / this.secondInMenute;
    return (
      this.getTwoCharNumber(menutes) + ':' + this.getTwoCharNumber(seconds)
    );
  }

  getTwoCharNumber(num: number): string {
    if (num < 10) {
      return '0' + num;
    } else {
      return '' + num;
    }
  }
}
