import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgHelpComponent } from './svg-help.component';

@NgModule({
  declarations: [SvgHelpComponent],
  exports: [SvgHelpComponent],
  imports: [CommonModule],
})
export class SvgHelpModule {}
