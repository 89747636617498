<mat-drawer-container class="container" autosize [class.isMain]="isMain">
  <mat-drawer
    *ngIf="{ showMenu: showMenu$ | async } as obs"
    [opened]="drawerOpenedSubject | async"
    [class.sidenav--side]="obs.showMenu"
    class="sidenav"
    [class.isMain]="isMain"
    [mode]="obs.showMenu ? 'side' : 'over'"
    (openedChange)="openedChange($event)">
    <app-drawer
      (drawerClick)="onDrawerClick(obs.showMenu)"
      [showMenu]="obs.showMenu"></app-drawer>
  </mat-drawer>
  <mat-drawer-content #toolbar class="content" (scroll)="onscroll()">
    <app-toolbar
      *ngIf="(showMenu$ | async) === false"
      [class.toolbar--visible]="showToolbarSubject | async"
      class="toolbar"
      (iconClick)="onDrawerClick(false)"></app-toolbar>
    <div
      class="sidenav-content"
      [class.sidenav-content--huge]="showMenu$ | async">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
