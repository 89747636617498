import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultRequest } from '@core-lib/models/common.interface';
import { bridgeMap } from '@core-lib/utils/bridge-map-pipe-rxjs/bridge-map.pipe';
import { map, Observable, switchMap } from 'rxjs';
import {
  ChangeProfileEmilResponse,
  Profile,
  ProfileMasterUserResponse,
  ProfileResponse,
  PROFILE_BRIDGE,
  PROFILE_MASTER_USER_BRIDGE,
} from './shared/profile.interface';

@Injectable()
export class ProfileApiService {
  private GET_PROFILE_URL = '/api/pmobile/client/profile';
  private GET_PROFILE_MASTER_USER_UserService_URL = '/master-user/customer';
  private CHANGE_PROFILE_EMAIL_URL = '/api/pmobile/client/changeEmail';

  constructor(private httpClient: HttpClient) {}

  getProfile(): Observable<Profile | undefined> {
    return this.httpClient
      .post<ResultRequest<ProfileResponse>>(this.GET_PROFILE_URL, {})
      .pipe(
        map((res) => res.result),
        bridgeMap(PROFILE_BRIDGE),
        switchMap((profileOrdinary) =>
          this.httpClient
            .get<ProfileMasterUserResponse>(
              this.GET_PROFILE_MASTER_USER_UserService_URL,
              {}
            )
            .pipe(
              bridgeMap(PROFILE_MASTER_USER_BRIDGE),
              map(
                (profileMasterUser) =>
                  ({
                    ...profileOrdinary,
                    ...profileMasterUser,
                  } as Profile)
              )
            )
        )
      );
  }

  changeEmail(email: string): Observable<string> {
    return this.httpClient
      .post<ChangeProfileEmilResponse>(this.CHANGE_PROFILE_EMAIL_URL, {
        params: {
          email,
        },
      })
      .pipe(map((res) => res.result));
  }
}
