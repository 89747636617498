import { ToTimeStringPipe } from './../../pipes/to-time-string.pipe';
import { TimerService } from './../../services/timer.service';
import { LoginCodeComponent } from './components/login-code/login-code.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BottomLoginSheetComponent } from './components/bottom-login-sheet/bottom-login-sheet.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BottomSheetAuthService } from './bottom-sheet-auth.service';
import { DestroyService } from '@core-lib/services/destroy.service';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    LoginComponent,
    BottomLoginSheetComponent,
    LoginCodeComponent,
    ToTimeStringPipe,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    NgxMaskModule.forRoot(),
    TranslateModule,
  ],
  providers: [BottomSheetAuthService, DestroyService, TimerService],
})
export class BottomSheetAuthModule {}
