import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TokenControlService,
  TokenPackage,
} from '@core-lib/modules/token-control/token-control.service';
import { environment } from '@mobility-pwa/environments/environment';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  mergeMap,
  Observable,
  of,
  retryWhen,
  take,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { AuthService } from '@core-lib/modules/auth-service/auth.service';
import { BottomSheetAuthService } from '@core-lib/modules/bottom-sheet-auth/bottom-sheet-auth.service';
const BASE_URL = environment.baseUrl;

export enum AppRequestError {
  TokenInvalid = 'Token not valid or not a HID token!',
  TokenNotFound = 'Access denied! Token not found!',
  AccessDenied = 'access_denied',
  AccessDeniedPayment = 'Access denied!',
  UserNotFound = 'User not found',
}

@Injectable()
export class AppMobilityHttpInterceptor implements HttpInterceptor {
  isRefreshingToken = false;

  tokenRefreshed$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private tokenControlService: TokenControlService,
    private bottomSheetAuthService: BottomSheetAuthService
  ) {}

  addToken(
    req: HttpRequest<any>,
    token: TokenPackage | null
  ): HttpRequest<any> {
    const bearerToken = token?.accessToken;
    return bearerToken
      ? req.clone({ setHeaders: { Authorization: `Bearer ${bearerToken}` } })
      : req;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return of(true).pipe(
      withLatestFrom(this.tokenControlService.currentAccessToken$),
      map(([, token]) => this.addToken(req, token)),
      concatMap((reqAuth) => next.handle(this.setCustomRequest(reqAuth))),
      map(this.catchRequestError),
      retryWhen(this.handle401Error)
    );
  }

  private handle401Error = (attempts: Observable<any>): Observable<any> => {
    return attempts.pipe(
      mergeMap((error, i) => {
        // Нам нужна только первая ошибка на устаревший токен
        if (error?.message === AppRequestError.TokenInvalid && i === 0) {
          if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenRefreshed$.next(false);

            return this.authService.refreshToken().pipe(
              catchError((err) => {
                // Отменяем заблокированные потоки
                this.tokenRefreshed$.complete();
                // показываем окно авторизации
                this.bottomSheetAuthService.checkAuth();
                return of(err);
              }),
              finalize(() => {
                this.tokenRefreshed$.next(true);
                this.isRefreshingToken = false;
              })
            );
          } else {
            // Блокируем потоки пока получаем токен
            return this.tokenRefreshed$.pipe(filter(Boolean), take(1));
          }
        }
        return throwError(error);
      })
    );
  };

  private catchRequestError(res: any) {
    // Если токен авторизации неверный
    if (
      res?.body?.error?.message === AppRequestError.TokenInvalid ||
      res?.body?.error?.message === AppRequestError.AccessDenied ||
      res?.body?.error?.message === AppRequestError.AccessDeniedPayment ||
      res?.body?.error?.message === AppRequestError.TokenNotFound
    ) {
      throw new Error(AppRequestError.TokenInvalid);
    }

    return res;
  }

  private setCustomRequest(req: HttpRequest<any>): HttpRequest<any> {
    const headers = req.headers
      .append(
        'X-Device-Id',
        'a8496ded4133c1cc358b8f612404bb1e32c22b0945c7e2984ae986cb2d479531_7d2d9334-c84c-469f-ad02-11a22c8aabf2'
      )
      .append('X-Project', 'mobility');
    return req.clone({
      url:
        (req.url.includes('i18n') || req.url.includes('assets')
          ? ''
          : BASE_URL) + req.url,
      headers,
    });
  }
}
