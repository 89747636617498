import { Injectable, Self } from '@angular/core';
import { DestroyService } from '@core-lib/services/destroy.service';
import { Store } from '@ngxs/store';
import { takeUntil, tap, throttleTime } from 'rxjs';
import { TokenControlService } from '../token-control/token-control.service';
import {
  ChangeProfileEmailAction,
  ClearProfileAction,
  LoadingProfileAction,
} from './profile.actions';
import { ProfileStateModule } from './shared/profile-state.interface';

@Injectable()
export class ProfileStoreService {
  constructor(
    private tokenControService: TokenControlService,
    private store: Store,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.tokenControService.currentAccessToken$
      .pipe(
        throttleTime(100),
        tap((token) =>
          token
            ? this.store.dispatch(new LoadingProfileAction())
            : this.store.dispatch(new ClearProfileAction())
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  profile$ = this.store.select(
    (state: { profile: ProfileStateModule }) => state.profile.profile
  );

  refreshProfile() {
    this.store.dispatch(new LoadingProfileAction());
  }

  changeEmail(email: string): void {
    this.store.dispatch(new ChangeProfileEmailAction(email));
  }
}
