import { SettingsStateModule } from '@core-lib/modules/settings-store/shared/settings-state.interface';

export class SettingsCityChanged {
  public static readonly type = '[Settings Store] City Changed';
  constructor(public payload: { city: string }) {}
}

export class SettingsCommonChanged {
  public static readonly type = '[Settings Store] Common Changed';
  constructor(
    public readonly payload: Pick<
      SettingsStateModule,
      'language' | 'city' | 'theme'
    >
  ) {}
}

export class SettingsLanguageSet {
  public static readonly type = '[Settings Store] Language Set';
}
