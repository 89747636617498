import { Injectable, Self } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DestroyService } from '@core-lib/services/destroy.service';
import { filter, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { TokenControlService } from '../token-control/token-control.service';
import { BottomLoginSheetComponent } from './components/bottom-login-sheet/bottom-login-sheet.component';

@Injectable()
export class BottomSheetAuthService {
  private authBottomSheet!: MatBottomSheetRef;
  private checkingTokenSubject = new Subject<boolean>();

  constructor(
    private tokenControlService: TokenControlService,
    private _bottomSheet: MatBottomSheet,
    @Self() private readonly destroy$: DestroyService
  ) {
    this.checkingTokenSubject
      .asObservable()
      .pipe(
        filter((checkingToken) => checkingToken),
        withLatestFrom(this.tokenControlService.currentAccessToken$),
        filter(([_, token]) => !token),
        tap(() => this.openBottomSheet()),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.tokenControlService.currentAccessToken$
      .pipe(
        filter((token) => !!token),
        tap(() => this.close()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private openBottomSheet(): void {
    this.authBottomSheet = this._bottomSheet.open(BottomLoginSheetComponent, {
      panelClass: 'bottom-login-sheet',
      disableClose: true,
      autoFocus: true,
    });
  }

  private close(): void {
    if (this.authBottomSheet) {
      this.authBottomSheet.dismiss();
    }
  }

  checkAuth() {
    this.checkingTokenSubject.next(true);
  }
}
