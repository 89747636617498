import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgLogoComponent } from './svg-logo.component';

@NgModule({
  declarations: [SvgLogoComponent],
  exports: [SvgLogoComponent],
  imports: [CommonModule],
})
export class SvgLogoModule {}
