import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgDocumentsComponent } from './svg-documents.component';

@NgModule({
  declarations: [SvgDocumentsComponent],
  exports: [SvgDocumentsComponent],
  imports: [CommonModule],
})
export class SvgDocumentsModule {}
